<template>
    <div :class="{'c-audio-player--is-loading': !ready, 'is-hidden': $xp.navigation.isNavHidden && ready && bottomFixed && playing, 'c-audio-player': true, 'c-audio-player--bottom-fixed': bottomFixed, 'landscape': $xp.screen && $xp.screen.orientation ==='landscape-primary'}" id="mediaplayer">
      <div class="c-audio-player__wrapper">
        <div class="c-audio-player__duration"
        role="progressbar"
        :aria-valuenow="(duration - duration*progress).toFixed()"
        :aria-valuetext="ariaRemaingingTime"
        aria-valuemin="0"
        :aria-valuemax="duration.toFixed()"
        :aria-label="$t('global.remainingTime')">
          {{ ((progress * duration) - duration) | formatPlayTime }}
        </div>
        <vue-ellipse-progress
          :angle="-90"
          animation="default 100 0"
          color="#fff"
          dash="0"
          emptyColor="rgba(0,0,0,.3)"
          emptyThickness="6"
          fontColor="white"
          fontSize="2rem"
          :legend="false"
          legendClass="legend-custom-style"
          line="butt"
          lineMode="in-over"
          :loading="false"
          :noData="false"
          :progress="progress*100"
          :size="90"
          :thickness="6">

          <div slot="legend-value"></div>
          <div slot="legend-caption">
            <div class="c-audio-player__play">
              <button class="c-audio-player__button" :class="{'not-ready': !this.ready }" @click="togglePlayback()" tabindex="0" :title="$t('global.play')" :aria-label=" playing ? $t('global.pause') : $t('global.play')">
                <span class="xp xp-pause xp--large" aria-hidden="true" v-if="playing"></span>
                <span class="xp xp-video-audio-playagain xp--large" aria-hidden="true" v-else-if="showPlayAgain"></span>
                <span class="xp xp-video-audio-play xp--large" aria-hidden="true" v-else></span>
              </button>
            </div>
          </div>

        </vue-ellipse-progress>
        <div class="c-audio-player__ffrew">
          <button class="c-audio-player__button" @click="stepBackward()" tabindex="0" :title="$t('global.rewind')" :aria-label="$t('global.rewind')">
            <span class="xp xp-fast-rewind xp--large" aria-hidden="true"></span>
          </button>
          <button class="c-audio-player__button" @click="stepForward()" tabindex="0" :title="$t('global.forward')" :aria-label="$t('global.forward')">
            <span class="xp xp-fast-forward xp--large" aria-hidden="true"></span>
          </button>
        </div>
        <div class="c-audio-player__volume-wrapper">
          <button class="c-audio-player__volume-button" @click="showVolumeControl = !showVolumeControl" tabindex="0" :aria-label="$t('global.setVolume')" v-if="$xp.media.allowUIVolumeControl">
              <span class="xp xp-volume-up xp--large" aria-hidden="true" v-if="$xp.media.volume >= 0.5"></span>
              <span class="xp xp-volume-down xp--large" aria-hidden="true" v-if="$xp.media.volume < 0.5 && $xp.media.volume !== 0"></span>
              <span class="xp xp-volume-off xp--large" aria-hidden="true" v-if="$xp.media.volume === 0" name="volume-off"></span>
          </button>
          <div class="c-audio-player__volume-slider"  v-if="showVolumeControl" >
              <slider v-bind:value.sync="playbackVolume" :orientation="$xp.screen && $xp.screen.orientation==='landscape-primary'?'horizontal':'vertical'" :trackSize=10></slider>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import EventHub from '../util/EventHub'
import lipSync from '../mixins/lipSync'

/**
 * Audio player for audio files.
 * @displayName Audio Player
 */
export default {
  mixins: [lipSync],
  props: {
    audioSync: {
      default: () => ({})
    },
    source: {
      type: String,
      required: true
    },
    bottomFixed: {
      default: function () {
        return true
      }
    },
    binaryId: {
      type: Number,
      required: true
    },
    pageId: {
      type: Number,
      required: true
    },
    autoPlay: {
      default: false
    },
    lipSyncUrl: {
      type: String,
      default: null,
      validator: value => {
        return value === null || ((typeof value === 'string' || value instanceof String) && value.startsWith('ws://'))
      }
    },
    loop: {
      default: false
    }
  },
  data () {
    return {
      showVolumeControl: false,
      audioSyncKeys: [],
      playing: false,
      showPlayAgain: false,
      progress: 0,
      duration: 0,
      ready: false
    }
  },
  mounted () {
    this.$xp.device.getRemappedUrl(this.source).then((remappedUrl) => {
      if (this.$xp.device.platform === 'ios') {
        if (window.XpedeoPreloader) {
          remappedUrl = window.XpedeoPreloader.convertProxyUrl(remappedUrl)
        }
      }

      if (typeof this.audioSync.Manual !== 'undefined') {
        this.$xp.navigation.bManualSlideshow = true
      }

      // console.log(this.audioSync)
      if (!this.$xp.navigation.bManualSlideshow) {
        this.audioSyncKeys = Object.keys(this.audioSync)
        for (let i = 0; i < this.audioSyncKeys.length; i++) {
          this.audioSyncKeys[i] = parseInt(this.audioSyncKeys[i])
        }
      }

      this.sound = document.createElement('audio')
      this.sound.autoplay = this.autoplay
      this.sound.classList.add('c-audio-player__element')
      this.sound.controls = false
      this.sound.src = remappedUrl
      this.sound.type = 'audio/mp3'
      this.sound.disableRemotePlayback = true
      this.sound.volume = this.$xp.media.volume
      this.sound.loop = this.loop
      this.sound.load()
      this.$el.appendChild(this.sound)
      // this.sound = this.$el.querySelector('.js-audioElement')
      this.sound.addEventListener('loadedmetadata', this.onLoadedMetadata)
      this.sound.addEventListener('canplaythrough', this.onCanPlayThrough)
      this.sound.addEventListener('canplay', this.onCanPlay)
      this.sound.addEventListener('play', this.onAudioPlay)
      this.sound.addEventListener('timeupdate', this.onTimeUpdate)
      this.sound.addEventListener('pause', this.onAudioPause)
      this.sound.addEventListener('ended', this.onAudioEnded)
      this.sound.addEventListener('error', this.onAudioError)
    })
    EventHub.Bus.$on(EventHub.TYPES.START_PLAY_MEDIA, this.onStartPlayMedia)
    EventHub.Bus.$on(EventHub.TYPES.ANDROID_ACTIVITY_PAUSE, this.onActivityPause)
    window.addEventListener('keyup', this.onKeyEvent)
  },
  beforeDestroy () {
    this.sound.autoplay = false
    this.sound.muted = true
    this.$emit('media-session-ended')
    this.stopLipSync()
    this.isLipSync && this.$xp.wifi.releaseWifiConnectionOn('videoSync')
    this.sound.removeEventListener('loadedmetadata', this.onLoadedMetadata)
    this.sound.removeEventListener('canplaythrough', this.onCanPlayThrough)
    this.sound.removeEventListener('canplay', this.onCanPlay)
    this.sound.removeEventListener('play', this.onAudioPlay)
    this.sound.removeEventListener('timeupdate', this.onTimeUpdate)
    this.sound.removeEventListener('pause', this.onAudioPause)
    this.sound.removeEventListener('ended', this.onAudioEnded)
    this.sound.removeEventListener('error', this.onAudioError)
    this.sound.removeEventListener('keyup', this.onKeyEvent)
    EventHub.Bus.$off(EventHub.TYPES.START_PLAY_MEDIA, this.onStartPlayMedia)
    EventHub.Bus.$off(EventHub.TYPES.ANDROID_ACTIVITY_PAUSE, this.onActivityPause)
    if (this.playing) {
      this.sound.pause()
      this.onAudioPause()
    }
    this.sound.parentNode.removeChild(this.sound)
    this.sound.removeAttribute('src')
    this.sound.load()
    this.sound = null
  },
  methods: {
    onLoadedMetadata (error) {
      console.log(error)
      this.duration = this.sound.duration
    },
    onCanPlay () {
      // Fallback for Firefox 62.1 sometimes does not send 'canplaythrough'
      if (this.sound && !this.ready) {
        setTimeout(() => {
          if (!this.ready) {
            this.onCanPlayThrough()
          }
        }, 4000)
      }
    },
    async onCanPlayThrough () {
      if (this.sound) {
        if (this.isLipSync) {
          if (!this.ready) {
            await this.$xp.wifi.lockWifiConnectionOn('videoSync')
            await this.$xp.network.waitForOnline()
            this.startLipSync(this.sound)
          }
        } else {
          const shouldRestoreState = !this.ready && this.$route.query.ref !== 'beacon'
          console.log('shouldRestoreState: ' + shouldRestoreState)
          const historyCurrentTime = this.$xp.history.historyParams['currentTime' + this.binaryId]
          if (shouldRestoreState && historyCurrentTime) {
            this.sound.currentTime = historyCurrentTime
            if (this.sound.currentTime >= this.sound.duration - 1) {
              this.showPlayAgain = true
            }
          }
          const historyPlayingState = this.$xp.history.historyParams['isPlaying' + this.binaryId]
          let audioPromise
          if (shouldRestoreState && typeof historyPlayingState !== 'undefined') {
            historyPlayingState ? audioPromise = this.sound.play() : audioPromise = this.sound.pause()
          } else if (!this.ready && this.autoPlay) {
            audioPromise = this.sound.play()
          }
          if (audioPromise !== undefined) {
            audioPromise.catch(error => {
              console.log('Cannot Autoplay Audio \n', error)
              // Auto-play was prevented
              // Show a UI element to let the user manually start playback
            })
          }
          this.ready = true
        }
      }
    },
    onTimeUpdate () {
      if (this.sound && this.ready) {
        const currentTime = this.sound.currentTime
        this.progress = currentTime / this.sound.duration
        if (parseInt(this.$route.params.id) === this.pageId) {
          this.$xp.history.setHistoryParam(this.pageId, 'currentTime' + this.binaryId, currentTime)
        }
      }
    },
    onAudioPlay () {
      this.setPlayingState(true)
      EventHub.Bus.$emit(EventHub.TYPES.START_PLAY_MEDIA, this._uid)
      this.$emit('media-playing')
    },
    onAudioPause () {
      this.setPlayingState(false)
      this.$emit('media-pause')
    },
    onAudioEnded () {
      // console.log('onAudioEnded')
      if (this.sound && !this.loop) {
        this.sound.pause()
        // Jump a little bit back, so on going back to this page onVideoEnded() is not called automatically again
        this.sound.currentTime -= 0.1
        this.onTimeUpdate()
        this.setPlayingState(false)
        if (this.$xp.navigation.childPagesMenuItems.length && this.$xp.navigation.isChildPagesMenuHidden && !this.$xp.settings.mergedSettings.navigation.mediaIgnoresContextMenu) {
          this.showPlayAgain = true
          this.$xp.navigation.toggleChildPagesMenu()
        } else if ((this.$xp.navigation.childPagesMenuItems.length === 0 || this.$xp.settings.mergedSettings.navigation.mediaIgnoresContextMenu) && this.$xp.history.nextPageId && this.$xp.settings.mergedSettings.navigation.nextPageOnMediaEnd) {
          this.$router.push('/page/' + this.$xp.history.nextPageId)
        } else if (this.$xp.navigation.childPagesMenuItems.length === 0 && this.$xp.settings.mergedSettings.navigation.backOnAudioEnd) {
          this.$xp.history.back()
        } else {
          // Erneut abspielen anbieten
          this.showPlayAgain = true
        }
      }
    },
    onAudioError (e) {
      console.log(e)
    },
    onStartPlayMedia (uid) {
      if (this.sound && this._uid !== uid) {
        this.sound.pause()
      }
    },
    onActivityPause () {
      if (this.sound) {
        this.sound.pause()
      }
    },
    setPlayingState (isPlaying) {
      this.playing = isPlaying
      this.$xp.media.setIsMediaPlaying(isPlaying)
      if (parseInt(this.$route.params.id) === this.pageId) {
        this.$xp.history.setHistoryParam(this.pageId, 'isPlaying' + this.binaryId, isPlaying)
      }
    },
    togglePlayback () {
      if (this.sound && this.ready) {
        if (!this.playing && this.showPlayAgain) {
          this.sound.currentTime = 0
          this.showPlayAgain = false
          this.sound.play()
        } else if (!this.playing) {
          this.sound.play()
        } else {
          this.sound.pause()
        }
      }
    },
    stepBackward () {
      if (this.sound && this.ready) {
        this.sound.currentTime -= 15
        this.showPlayAgain = false
        this.onTimeUpdate()
      }
    },
    stepForward () {
      if (this.sound && this.ready) {
        this.sound.currentTime += 15
        this.onTimeUpdate()
      }
    },
    onKeyEvent (e) {
      const nKey = e.keyCode
      // console.log("onKeyEvent " + nKey);
      switch (nKey) {
        case 27: // ESC
          console.log('KeyEvent: Audio StopPlay')
          this.sound.pause()
          break

        case 32: // Space
        case 75: // K
          console.log('KeyEvent: Audio TogglePlay')
          this.togglePlayback()
          break

        case 37: // <-
          console.log('KeyEvent: Audio Backward')
          this.sound.currentTime -= 15
          this.onTimeUpdate()
          break

        case 39: // ->
          console.log('KeyEvent: Audio Forward')
          this.sound.currentTime += 15
          this.onTimeUpdate()
          break
        /** NO NEED for volum control in browser */
        // case 38: // arrow up
        //   if (this.showVolumeControl) {
        //     console.log('KeyEvent: Audio Volume Up', this.playbackVolume)
        //     this.playbackVolume = Math.min(this.playbackVolume + 0.1, 1)
        //   }
        //   break

        // case 40: // arrow down
        //   if (this.showVolumeControl) {
        //     console.log('KeyEvent: Audio Volume Down', this.playbackVolume)
        //     this.playbackVolume = Math.max(this.playbackVolume - 0.1, 0)
        //   }
        //   break
      }
    },
    locationOf (element, array, start, end) {
      start = start || 0
      end = end || array.length
      const pivot = parseInt(start + (end - start) / 2, 10)
      if (end - start <= 1 || array[pivot] === element) {
        return pivot
      }
      if (array[pivot] < element) {
        return this.locationOf(element, array, pivot, end)
      } else {
        return this.locationOf(element, array, start, pivot)
      }
    }
  },
  computed: {
    isLipSync () {
      return this.lipSyncUrl != null
    },
    track: {
      get: function () {
        return this.progress
      },
      set: function (v) {
        if (this.sound) {
          if (!Number.isNaN(v)) {
            this.sound.currentTime = v * this.duration
          } else {
            console.log('invalid time: ' + v)
          }
        }
      }
    },
    playbackVolume: {
      get: function () {
        return parseFloat(this.$xp.media.volume)
      },
      set: function (v) {
        if (!Number.isNaN(v)) {
          this.$xp.media.setGlobalVolume(v)
          this.sound.volume = v
        } else {
          console.log('invalid volume: ' + v)
        }
      }
    },
    ariaRemaingingTime () {
      const remaingingSeconds = this.duration - (this.progress * this.duration)
      const minutes = Math.floor(remaingingSeconds / 60)
      const seconds = Math.floor(remaingingSeconds % 60)
      return this.$t('global.ariaRemainingTime', [minutes, seconds])
    }
  },
  watch: {
    '$xp.navigation.isNavHidden': function (value) {
      if (value === true) {
        this.showVolumeControl = false
      }
    },
    progress: function (value) {
      const time = (this.duration * value).toFixed(0)
      const audioSyncItem = this.audioSync[this.audioSyncKeys[this.locationOf(time, this.audioSyncKeys)]]
      let entry = ''
      if (audioSyncItem && !this.$xp.media.isImageZoomDialogActive) {
        entry = `entry-${audioSyncItem.entryId}`
        this.$xp.navigation.goToEntry(entry)
      }
      this.$emit('media-progress-changed')
    }
  },
  components: {
  }
}
</script>
